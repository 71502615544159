import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageSixService {
  static createStageSix (data) {
    return axios.post(`${url}/app/stage-six/create`, data)
  }
}

export default StageSixService
